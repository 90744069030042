import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Layout from "../components/layout";

const Payment = () => {
    return (
        <Layout>
            <div className="payment_desc">
                We will only change you a fee if you use the chargeable services on DVV invoice. You may see a temporary authorisation on your card, which your bank should release shortly.
            </div>
            <div className="center_colm">
                <div className="center_colm_title">
                    Credit or Debit Card
                </div>
                <div className="ct_item card_number">
                    <label htmlFor="ct_item">Card number<FontAwesomeIcon className="fa_icon" icon={faInfoCircle} /></label>
                    <input type="text" name="card_number" id="card_number" placeholder="Enter your card number" autoFocus />
                </div>
                <div className="sct_item">
                    <div className="ct_item">
                        <label htmlFor="ct_item">Expiry date</label>
                        <input type="text" name="expiry_date" id="expiry_date" placeholder="MM/YY" />
                    </div>
                    <div className="ct_item">
                        <label htmlFor="ct_item">CVV</label>
                        <input type="text" name="cvv" id="cvv" placeholder="Enter CVV" />
                    </div>
                </div>
                <div className="ct_item">
                    <label htmlFor="ct_item">Name of the cardholder</label>
                    <input type="text" name="card_holder" id="card_holder" placeholder="Enter card holder name" />
                </div>
                <div className="ct_item">
                    <label htmlFor="ct_item">Payment address</label>
                    <input type="text" name="payment_address" id="payment_address" placeholder="Enter your address" />
                </div>
                <div className="sct_item">
                    <button className="start_m_btn btn">
                        Pay without save
                    </button>
                    <button className="start_m_btn btn">
                        Save payment method
                    </button>
                </div>
            </div>
        </Layout>
    )
}
export default Payment

